<form name="form" (ngSubmit)="acceptCampaign()" #f="ngForm" novalidate>
  <!-- Invite Form -->
  <div *ngIf="!submitted">
    <div class="form-body">
      <button class="button-orange" [disabled]="submitting" id="create-account">
        {{ "partner.acceptCampaign.joinCampaignText" | translate }}
      </button>
      <div class="decline-link">
        <a (click)="declineCampaignConfirm()">
          <span class="decline-text">
            {{ "partner.acceptCampaign.declineText" | translate }}
          </span>
        </a>
      </div>
    </div>
  </div>
  <!-- Sign Up Success message -->
  <div *ngIf="submitted">
    <div class="form-body-success">
      <h2>{{ "partner.acceptCampaign.successText" | translate }}</h2>
    </div>
  </div>
</form>
