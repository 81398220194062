import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { AppDataService } from "./api/app-data.service";
import { AuthenticationService } from "./api/authentication.service";
import { BrandService } from "./api/brand.service";
import { CampaignService } from "./api/campaign.service";
import { PartnerCampaignService } from "./api/partner-campaign.service";
import { PartnerService } from "./api/partner.service";
import { UserService } from "./api/user.service";
import { CacheService } from "./cache.service";
import { CommonDialogService } from "./common-dialog.service";
import { DialogService } from "./dialog.service";
import { HttpClient } from "./http-client";
import { ImageService } from "./image.service";
import { LocalStorageService } from "./local-storage.service";
import { LanguageService } from "./language.service";
import { NotificationService } from "./notification.service";
import { RequestErrorService } from "./request-error.service";
import { RouteWithDataService } from "./route-with-data.service";
import { UrlFormatterService } from "./url-formatter-service";
import { EmailValidatorService } from "./email-validator-service";
import { AuthTokenService } from "./api/auth-token.service";
import { SessionStorageService } from "./session-storage.service";
import { DateService } from "./date.service";
import { FeatureFlagsService } from "./feature-flags.service";

@NgModule({
  imports: [TranslateModule],
  providers: [
    AppDataService,
    AuthenticationService,
    AuthTokenService,
    BrandService,
    CampaignService,
    CommonDialogService,
    CommonDialogService,
    DateService,
    DialogService,
    DialogService,
    EmailValidatorService,
    FeatureFlagsService,
    HttpClient,
    ImageService,
    LanguageService,
    LocalStorageService,
    NotificationService,
    PartnerCampaignService,
    PartnerService,
    RequestErrorService,
    RouteWithDataService,
    SessionStorageService,
    UrlFormatterService,
    UserService,
    CacheService,
  ],
  exports: [TranslateModule],
})
export class SharedServicesModule {}
