import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { AccountVerifiedComponent } from "./account-verified/account-verified.component";
import { BrandEmailConnectedComponent } from "./brand-email-connected/brand-email-connected.component";
import { SamplePageComponent } from "./sample-page/sample-page.component";
import { VerifyAccountErrorComponent } from "./verify-account-error/verify-account-error.component";

@NgModule({
  declarations: [
    AccountVerifiedComponent,
    BrandEmailConnectedComponent,
    SamplePageComponent,
    VerifyAccountErrorComponent,
  ],
  imports: [TranslateModule],
  exports: [
    BrandEmailConnectedComponent,
    AccountVerifiedComponent,
    VerifyAccountErrorComponent,
  ],
})
export class StaticModule {}
