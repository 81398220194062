import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { LayoutModule } from "../layout/layout.module";
import { SharedComponentsModule } from "../shared/components/shared-components.module";
import { AngularMaterialModule } from "../shared/material/material.module";
import { SharedPipesModule } from "../shared/pipes/shared-pipes.module";

import { CampaignChartsScreenshotComponent } from "./campaign-charts-screenshot/campaign-charts-screenshot.component";
import { CampaignDashboardDailyChartsComponent } from "../shared/components/campaign-dashboard-daily-charts/campaign-dashboard-daily-charts.component";
import { CampaignDashboardStatsSectionComponent } from "../shared/components/campaign-dashboard-stats-section/campaign-dashboard-stats-section.component";

@NgModule({
  declarations: [CampaignChartsScreenshotComponent],
  imports: [
    RouterModule,
    FormsModule,
    CommonModule,
    LayoutModule,
    SharedComponentsModule,
    SharedPipesModule,
    AngularMaterialModule,
    CampaignDashboardDailyChartsComponent,
    CampaignDashboardStatsSectionComponent,
  ],
})
export class ScreenshotModule {}
