<div>
  <img class="logo" src="/assets/images/as_socialpals_logo_orange.svg" />
  <h1>
    <p>{{ "static.verifyAccountError.title" | translate }}</p>
    <p>{{ "static.verifyAccountError.subtitle" | translate }}</p>
  </h1>
  <button (click)="continue()" class="button button-orange continue-button">
    {{ "static.verifyAccountError.button" | translate }}
  </button>
</div>
