import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BrandCampaignInsightService } from "../../brand/brand-campaign/shared/services/brand-campaign-insight.service";

import {
  BrandCampaignInsight,
  BrandCampaignInsightChart,
} from "../../shared/models/brandCampaignInsight";

@Component({
  selector: "app-campaign-charts-screenshot",
  templateUrl: "./campaign-charts-screenshot.component.html",
  styleUrl: "./campaign-charts-screenshot.component.scss",
  providers: [BrandCampaignInsightService],
})
export class CampaignChartsScreenshotComponent {
  protected SCREEN_CHARTS = "charts";
  protected SCREEN_INFO = "summary";
  protected insights?: BrandCampaignInsight;
  protected insightCharts?: BrandCampaignInsightChart;
  protected loading = true;
  protected screen: string;

  constructor(
    private readonly brandCampaignInsightService: BrandCampaignInsightService,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    this.getBrandCampaignInsights();
    this.screen = activatedRoute.snapshot.params.screen;

    if (this.screen === this.SCREEN_CHARTS) {
      this.getBrandCampaignInsightCharts();
    }
  }

  private getBrandCampaignInsights(): void {
    this.loading = true;
    this.brandCampaignInsightService
      .getBrandCampaignInsightsFromToken(
        this.activatedRoute.snapshot.params.token,
      )
      .subscribe((insights: BrandCampaignInsight) => {
        this.loading = false;
        this.insights = insights;
      });
  }

  private getBrandCampaignInsightCharts(): void {
    this.brandCampaignInsightService
      .getBrandCampaignInsightChartsFromToken(
        this.activatedRoute.snapshot.params.token,
      )
      .subscribe((insightCharts: BrandCampaignInsightChart) => {
        this.insightCharts = insightCharts;
      });
  }
}
