<div>
  <img class="logo" src="/assets/images/as_socialpals_logo_orange.svg" />
  <h1>
    <p>{{ "static.unsupportedBrowserDescription.line1" | translate }}</p>
    <p>{{ "static.unsupportedBrowserDescription.line2" | translate }}</p>
  </h1>
  <div class="logos">
    <div class="browser">
      <a href="https://www.apple.com/safari/">
        <img
          class="logo"
          alt="safari"
          src="../../../assets/images/safari-logo.png"
        />
        <h6>SAFARI</h6>
      </a>
    </div>
    <div class="browser">
      <a href="https://www.google.com/chrome/">
        <img
          class="logo"
          preserveAspectRatio="xMinYMin meet"
          alt="chrome"
          src="../../../assets/images/chrome-logo.svg"
        />
        <h6>CHROME</h6>
      </a>
    </div>
    <div class="browser">
      <a href="https://www.mozilla.org/firefox/new/">
        <img
          class="logo"
          preserveAspectRatio="xMinYMin meet"
          alt="firefox"
          src="../../../assets/images/firefox-logo.svg"
        />
        <h6>FIREFOX</h6>
      </a>
    </div>
    <div class="browser">
      <a href="https://www.microsoft.com/edge">
        <img
          class="logo"
          preserveAspectRatio="xMinYMin meet"
          alt="edge"
          src="../../../assets/images/edge-logo.svg"
        />
        <h6>EDGE</h6>
      </a>
    </div>
  </div>
  <h2>
    <p>{{ "static.unsupportedBrowserFooter.line1" | translate }}</p>
    <p>{{ "static.unsupportedBrowserFooter.line2" | translate }}</p>
  </h2>
</div>
