<div
  class="background"
  [style.background-image]="'url(' + signUpBackgroundImage + ')'"
></div>
<img
  class="logo"
  src="assets/images/as_socialpals_logotype.svg"
  alt="socialPALS"
/>
<div *ngIf="!campaignNotFound" class="campaign-content">
  <h3 *ngIf="partner?.companyName" class="greeting-text">
    {{
      "partner.campaign.helloMessage"
        | translate
          : {
              partnerName: partner.companyName
            }
    }}<br />
    {{ "partner.campaign.helloSubMessage" | translate }}
  </h3>
  <h3 *ngIf="!partner?.companyName" class="greeting-text">
    {{ "partner.campaign.helloSubMessage" | translate }}
  </h3>
  <img
    *ngIf="brandCampaign?.logo"
    class="brand-logo"
    [src]="brandCampaign.logo"
    alt="{{ brandCampaign.brand?.companyName }}"
  />
  <h2>{{ campaign?.name }}</h2>
</div>
<div *ngIf="campaignNotFound" class="campaign-content">
  <h3 class="greeting-text">
    {{ "partner.campaign.campaignNotFound" | translate }}
  </h3>
</div>

<app-sign-up
  *ngIf="contentType === 'sign-up' && !isPreview; else content"
  [partner]="partner"
  (partnerSignedUp)="onEventPartnerSignedUp()"
></app-sign-up>

<ng-template #content>
  <router-outlet></router-outlet>
</ng-template>

<app-partner-accept-campaign
  *ngIf="contentType === 'invite'"
  (partnerAnsweredInvite)="onEventPartnerAnsweredInvite($event)"
></app-partner-accept-campaign>
<app-lang-selector class="header-section"></app-lang-selector>

<div class="legal-links">
  <div
    class="legal-link"
    [innerHtml]="'shared.privacyPolicy' | withLink: 'url.privacy'"
  ></div>
  <span class="separator">|</span>
  <div
    class="legal-link"
    [innerHtml]="'shared.termsAndConditions' | withLink: 'url.terms'"
  ></div>
  <span class="separator">|</span>
  <a class="legal-link" href="https://www.socialpals.de" target="_blank"
    >socialPALS</a
  >
</div>
