import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";

import { AuthenticationService } from "../../shared/services/api/authentication.service";
import { UserAccessService } from "../shared/services/user-access.service";

@Component({
  selector: "app-single-sign-on-callback",
  templateUrl: "./single-sign-on-callback.component.html",
  styleUrls: ["./single-sign-on-callback.component.scss"],
})
export class SingleSignOnCallbackComponent implements OnInit {
  private accessToken?: string;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly authenticationService: AuthenticationService,
    private readonly userAccessService: UserAccessService,
  ) {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.accessToken = params.access_token;
    });
  }

  public ngOnInit(): void {
    this.startSSOLogin();
  }

  private startSSOLogin(): void {
    if (!this.accessToken) {
      this.redirectAfterFail();
      return;
    }

    this.authenticationService.storeUserSession(this.accessToken);
    this.userAccessService.setUserData().subscribe(() => {
      // ...and navigate to the welcome page
      this.router.navigate(["welcome"]);
    });
  }

  private redirectAfterFail(): void {
    this.router.navigate(["login"]);
  }
}
