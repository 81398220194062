<div *ngIf="!loginError" class="sso-iframe">
  <div class="sso-iframe-loading">
    <mat-progress-bar [color]="'primary'" mode="buffer"></mat-progress-bar>
  </div>
</div>
<div *ngIf="loginError" class="sso-error">
  <div class="sso-error-action">
    <img height="200" src="/assets/images/reload-page-man.png" />
    <div class="sso-error-action-text">
      {{ "iframeSso.ssoLoginFailedMessage" | translate }}
    </div>
  </div>
</div>
