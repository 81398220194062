<form
  name="form"
  (ngSubmit)="f.form.valid && recoverPassword(model.email)"
  #f="ngForm"
  novalidate
  *ngIf="!model.token"
>
  <h1>{{ "recoverPassword.title" | translate }}</h1>
  <div class="form-body" *ngIf="!submitted">
    <mat-form-field class="fieldset">
      <mat-label>{{ "shared.emailAddress" | translate }}</mat-label>
      <input
        matInput
        #email="ngModel"
        [(ngModel)]="model.email"
        id="email"
        name="email"
        type="email"
        required
        email
      />

      <mat-error
        class="email-error"
        *ngIf="email.errors && email.errors['required']"
      >
        {{ "shared.emailAddressIsRequired" | translate }}
      </mat-error>
      <mat-error
        class="email-error"
        *ngIf="email.errors && email.errors['email'] && !email.errors.required"
      >
        {{ "shared.enterValidEmailAddress" | translate }}
      </mat-error>
    </mat-form-field>
    <button
      class="button-orange"
      [disabled]="submitting"
      id="send-recovery-email"
    >
      {{ "recoverPassword.sendRecoveryEmail" | translate }}
    </button>
    <a class="button-grey" [routerLink]="['/login']" id="back-to-login">
      {{ "recoverPassword.backToLogin" | translate }}
    </a>
  </div>
  <div class="form-body" *ngIf="submitted">
    <img
      class="icon-check"
      src="../../../assets/images/icons/ic_check_orange.svg"
      alt=""
    />
    <p>{{ "recoverPassword.emailSent" | translate }}</p>
    <a class="button-grey" [routerLink]="['/login']">{{
      "recoverPassword.backToLogin" | translate
    }}</a>
  </div>
</form>

<form
  name="form"
  (ngSubmit)="(p.form.valid)"
  #p="ngForm"
  novalidate
  *ngIf="model.token"
>
  <h1>{{ "recoverPassword.setNewPassword" | translate }}</h1>
  <div class="form-body" *ngIf="!submitted">
    <mat-form-field class="fieldset">
      <mat-label>{{ "recoverPassword.newPassword" | translate }}</mat-label>
      <input
        matInput
        #password="ngModel"
        [(ngModel)]="model.password"
        id="password"
        name="password"
        type="password"
        required
        email
      />
      <mat-error
        class="password-error"
        *ngIf="password.errors && password.errors['required']"
      >
        {{ "recoverPassword.passwordRequired" | translate }}
      </mat-error>
    </mat-form-field>
    <button
      class="button-orange"
      (click)="savePassword()"
      [disabled]="submitting"
    >
      {{ "recoverPassword.updatePassword" | translate }}
    </button>
    <a class="button-grey" [routerLink]="['/login']">{{
      "recoverPassword.backToLogin" | translate
    }}</a>
  </div>
  <div class="form-body" *ngIf="submitted">
    <img
      class="icon-check"
      src="../../../assets/images/icons/ic_check_orange.svg"
      alt=""
    />
    <p>{{ "recoverPassword.passwordUpdated" | translate }}</p>
    <a class="button-grey" [routerLink]="['/login']">{{
      "recoverPassword.backToLogin" | translate
    }}</a>
  </div>
</form>
