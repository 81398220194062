import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { AppLocale } from "../../shared/models/appData";
import {
  BrandWhiteLabelConfigKeys,
  WhiteLabelKeysList,
} from "../../shared/models/white-label";
import { AuthenticationService } from "../../shared/services/api/authentication.service";
import {
  StorageKeys,
  LocalStorageService,
} from "../../shared/services/local-storage.service";
import { LanguageService } from "../../shared/services/language.service";
import { MySAPCookieAuthResponse } from "../../shared/services/responses/my-sap-cookie-auth-response";
import { UserAccessService } from "../shared/services/user-access.service";

@Component({
  selector: "app-single-sign-on-iframe",
  templateUrl: "./single-sign-on-iframe.component.html",
  styleUrls: ["./single-sign-on-iframe.component.scss"],
})
export class SingleSignOnIframeComponent implements OnInit {
  protected loginError = false;

  private token?: string;
  private inviteCampaignName?: string;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly authenticationService: AuthenticationService,
    private readonly userAccessService: UserAccessService,
    private readonly languageService: LanguageService,
    private readonly localStorageService: LocalStorageService,
  ) {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.token = params.token;
      this.inviteCampaignName = params.inviteCampaignName;
    });
  }

  public ngOnInit(): void {
    this.authenticationService.logout();
    this.loginError = false;

    if (!this.token) {
      this.loginFail();
    } else {
      this.startSSOLogin(this.token);
    }
  }

  private startSSOLogin(token: string): void {
    this.authenticationService
      .getTokenFromSSOMySAPCookie(
        token,
        this.inviteCampaignName ? this.inviteCampaignName : "0",
      )
      .subscribe({
        next: (cookieResponse) => {
          this.languageService.setLocale(cookieResponse.locale as AppLocale);
          this.authenticationService.storeUserSession(
            cookieResponse.accessToken,
          );
          this.localStorageService.store(
            StorageKeys.WhiteLabelBrandId,
            cookieResponse.brandId,
          );

          this.setStylesFromCookie(cookieResponse);
          this.moveUserToCampaignOrWelcome();
        },
        error: (error) => {
          console.error(error);
          // The SSO failed, we continue as normal user
          this.loginFail();
        },
      });
  }

  private moveUserToCampaignOrWelcome(): void {
    this.userAccessService.setUserData().subscribe(() => {
      if (this.inviteCampaignName) {
        this.router.navigate(["invite/" + this.inviteCampaignName]);
      } else {
        this.router.navigate(["welcome"]);
      }
    });
  }

  private setStylesFromCookie(cookie: MySAPCookieAuthResponse): void {
    if (!document.documentElement || !document.documentElement.style) {
      return;
    }

    for (const key of WhiteLabelKeysList) {
      const value = cookie[key.cookieParam];

      document.documentElement.style.setProperty(key.styleVar, value);
      this.localStorageService.store(key.storageKey, value);
    }

    if (cookie.brandId) {
      const FOOTER_PADDING = "16px";
      document.documentElement.style.setProperty(
        BrandWhiteLabelConfigKeys.WhiteLabelFooterPadding,
        FOOTER_PADDING,
      );
      this.localStorageService.store(
        StorageKeys.WhiteLabelFooterPadding,
        FOOTER_PADDING,
      );
    }
  }

  private loginFail(): void {
    this.authenticationService.logout();
    this.loginError = true;
  }
}
