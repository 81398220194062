import { Optional, Inject, InjectionToken } from "@angular/core";
import {
  DateAdapter,
  MatDateFormats,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import localeData from "dayjs/plugin/localeData";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { AppLanguage, isAppLanguage } from "../models/appData";

// Import DayJS locales
import "dayjs/locale/cs";
import "dayjs/locale/de";
import "dayjs/locale/en-gb";
import "dayjs/locale/es";
import "dayjs/locale/fr";
import "dayjs/locale/it";
import "dayjs/locale/nl";
import "dayjs/locale/pt";

export interface DayJsDateAdapterOptions {
  useUtc?: boolean;
}

// Parsing options for localized dates are limited, see: https://day.js.org/docs/en/display/format#list-of-localized-formats
// And, since we want the same format for input/display, we're limited to that same set also for display.
const INPUT_FORMAT = "L";

export const MAT_DAYJS_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: INPUT_FORMAT,
  },
  display: {
    dateInput: INPUT_FORMAT,
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

export const MAT_DAYJS_DATE_ADAPTER_OPTIONS_FACTORY =
  (): DayJsDateAdapterOptions => ({
    useUtc: false,
  });

export const MAT_DAYJS_DATE_ADAPTER_OPTIONS =
  new InjectionToken<DayJsDateAdapterOptions>(
    "MAT_DAYJS_DATE_ADAPTER_OPTIONS",
    {
      providedIn: "root",
      factory: MAT_DAYJS_DATE_ADAPTER_OPTIONS_FACTORY,
    },
  );

const LANG_TO_DAYJS_LOCALE: Record<AppLanguage, string> = {
  de: "de",
  en: "en-gb",
  fr: "fr",
  nl: "nl",
  it: "it",
  es: "es",
  cs: "cs",
  pt: "pt",
};

export class DayjsDateAdapter extends DateAdapter<dayjs.Dayjs> {
  // SAFETY `!`: This is initialised via `initializeParser` > `setLocale`
  private localeData!: {
    firstDayOfWeek: number;
    longMonths: string[];
    shortMonths: string[];
    dates: string[];
    longDaysOfWeek: string[];
    shortDaysOfWeek: string[];
    narrowDaysOfWeek: string[];
  };

  constructor(
    @Optional() @Inject(MAT_DATE_LOCALE) public dateLocale: string,
    @Optional()
    @Inject(MAT_DAYJS_DATE_ADAPTER_OPTIONS)
    private options?: DayJsDateAdapterOptions,
  ) {
    super();
    this.initializeParser(dateLocale);
  }

  range<T>(length: number, valueFunction: (index: number) => T): T[] {
    const valuesArray = Array(length);
    for (let i = 0; i < length; i++) {
      valuesArray[i] = valueFunction(i);
    }
    return valuesArray;
  }

  setLocale(locale?: string) {
    const defaultLocale = AppLanguage.En;
    const localeShort = (locale ?? defaultLocale).slice(0, 2);

    locale = isAppLanguage(localeShort)
      ? LANG_TO_DAYJS_LOCALE[localeShort]
      : LANG_TO_DAYJS_LOCALE[defaultLocale];

    dayjs.locale(locale);
    const dayJsLocaleData = dayjs().localeData();

    this.localeData = {
      firstDayOfWeek: dayJsLocaleData.firstDayOfWeek(),
      longMonths: dayJsLocaleData.months(),
      shortMonths: dayJsLocaleData.monthsShort(),
      dates: this.range(31, (i) => this.createDate(2017, 0, i + 1).format("D")),
      longDaysOfWeek: this.range(7, (i) =>
        this.dayJs().set("day", i).format("dddd"),
      ),
      shortDaysOfWeek: dayJsLocaleData.weekdaysShort(),
      narrowDaysOfWeek: dayJsLocaleData.weekdaysMin(),
    };

    super.setLocale(locale);
  }

  getYear(date: dayjs.Dayjs): number {
    return this.dayJs(date).year();
  }

  getMonth(date: dayjs.Dayjs): number {
    return this.dayJs(date).month();
  }

  getDate(date: dayjs.Dayjs): number {
    return this.dayJs(date).date();
  }

  getDayOfWeek(date: dayjs.Dayjs): number {
    return this.dayJs(date).day();
  }

  getMonthNames(style: "long" | "short" | "narrow"): string[] {
    return style === "long"
      ? this.localeData.longMonths
      : this.localeData.shortMonths;
  }

  getDateNames(): string[] {
    return this.localeData.dates;
  }

  getDayOfWeekNames(style: "long" | "short" | "narrow"): string[] {
    if (style === "long") {
      return this.localeData.longDaysOfWeek;
    }
    if (style === "short") {
      return this.localeData.shortDaysOfWeek;
    }
    return this.localeData.narrowDaysOfWeek;
  }

  getYearName(date: dayjs.Dayjs): string {
    return this.dayJs(date).format("YYYY");
  }

  getFirstDayOfWeek(): number {
    return this.localeData.firstDayOfWeek;
  }

  getNumDaysInMonth(date: dayjs.Dayjs): number {
    return this.dayJs(date).daysInMonth();
  }

  clone(date: dayjs.Dayjs): dayjs.Dayjs {
    return date.clone();
  }

  createDate(year: number, month: number, date: number): dayjs.Dayjs {
    if (month < 0 || month > 11) {
      throw Error(
        `Invalid month index "${month}". Month index has to be between 0 and 11.`,
      );
    }

    if (date < 1) {
      throw Error(`Invalid date "${date}". Date has to be greater than 0.`);
    }

    const returnDayjs = this.dayJs()
      .set("year", year)
      .set("month", month)
      .set("date", date);
    return returnDayjs;
  }

  today(): dayjs.Dayjs {
    return this.dayJs();
  }

  parse(value: any, parseFormat: string): dayjs.Dayjs | null {
    const formats = this.getParseFormats(parseFormat);

    if (value && typeof value === "string") {
      return this.dayJs(value, formats);
    }

    return value ? this.dayJs(value).locale(this.locale) : null;
  }

  private getParseFormats(parseFormat: string): string[] {
    const format = dayjs()
      .locale(this.locale)
      .localeData()
      .longDateFormat(parseFormat);

    return [
      format,
      format.replace("YYYY", "YY"),
      format.replace("MM", "M"),
      format.replace("DD", "D"),
      format.replace("YYYY", "YY").replace("MM", "M"),
      format.replace("YYYY", "YY").replace("DD", "D"),
      format.replace("MM", "M").replace("DD", "D"),
      format.replace("YYYY", "YY").replace("MM", "M").replace("DD", "D"),
    ];
  }

  format(date: dayjs.Dayjs, displayFormat: string): string {
    if (!this.isValid(date)) {
      throw Error("DayjsDateAdapter: Cannot format invalid date.");
    }
    return date.locale(this.locale).format(displayFormat);
  }

  addCalendarYears(date: dayjs.Dayjs, years: number): dayjs.Dayjs {
    return date.add(years, "year");
  }

  addCalendarMonths(date: dayjs.Dayjs, months: number): dayjs.Dayjs {
    return date.add(months, "month");
  }

  addCalendarDays(date: dayjs.Dayjs, days: number): dayjs.Dayjs {
    return date.add(days, "day");
  }

  toIso8601(date: dayjs.Dayjs): string {
    return date.toISOString();
  }

  deserialize(value: any): dayjs.Dayjs | null {
    let date;

    if (value instanceof Date) {
      date = this.dayJs(value);
    } else if (this.isDateInstance(value)) {
      return this.clone(value);
    }

    if (typeof value === "string") {
      if (!value) {
        return null;
      }
      date = this.dayJs(value).toISOString();
    }

    if (date && this.isValid(date)) {
      return this.dayJs(date);
    }

    return super.deserialize(value);
  }

  isDateInstance(obj: any): boolean {
    return dayjs.isDayjs(obj);
  }

  isValid(date: string | dayjs.Dayjs): boolean {
    return this.dayJs(date).isValid();
  }

  invalid(): dayjs.Dayjs {
    return this.dayJs(null);
  }

  private dayJs(date?: any, format?: string | string[]): dayjs.Dayjs {
    if (!this.shouldUseUtc) {
      return dayjs(date, format, true);
    }

    return dayjs(date, format, true).utc();
  }

  private get shouldUseUtc(): boolean {
    const { useUtc }: DayJsDateAdapterOptions = this.options || {};
    return !!useUtc;
  }

  private initializeParser(dateLocale: string) {
    if (this.shouldUseUtc) {
      dayjs.extend(utc);
    }

    dayjs.extend(LocalizedFormat);
    dayjs.extend(customParseFormat);
    dayjs.extend(localeData);

    this.setLocale(dateLocale);
  }
}
