import { Component, Inject, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";

import { UserService } from "../../shared/services/api/user.service";
import { NotificationService } from "../../shared/services/notification.service";

@Component({
  selector: "app-recover-password",
  templateUrl: "./recover-password.component.html",
  styleUrls: ["./recover-password.component.scss"],
})
export class RecoverPasswordComponent implements OnInit {
  protected model: any = {};
  protected submitting = false;
  protected submitted = false;

  constructor(
    @Inject(UserService)
    private readonly userService: UserService,
    private readonly notificationService: NotificationService,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  public ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.model.token = params.token;
    });
  }

  protected recoverPassword(email: string): void {
    this.submitting = true;
    this.userService.recoverPassword(email).subscribe({
      next: () => {
        this.submitting = false;
        this.submitted = true;
      },
      error: (err) => {
        const { error } = err.error;
        this.submitting = false;
        if (error && error.key === "USER_NOT_FOUND") {
          this.notificationService.error("recoverPassword.userNotFound");
        } else {
          this.notificationService.error("recoverPassword.canNotRequest");
        }
      },
    });
  }

  protected savePassword(): void {
    this.userService
      .setNewPassword(this.model.email, this.model.password, this.model.token)
      .subscribe({
        next: () => {
          this.submitting = false;
          this.submitted = true;
        },
        error: () => {
          this.submitting = false;
          this.notificationService.error("recoverPassword.canNotSave");
        },
      });
  }
}
