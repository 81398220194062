<div class="wrap">
  <app-campaign-dashboard-stats-section
    *ngIf="!loading && insights && screen === SCREEN_INFO"
    [insights]="insights"
  />

  <app-campaign-dashboard-daily-charts
    *ngIf="!loading && insightCharts && insights && screen === SCREEN_CHARTS"
    [insightCharts]="insightCharts"
    [insights]="insights"
  />
</div>
