import { MatPaginatorIntl } from "@angular/material/paginator";
import { TranslateParser, TranslateService } from "@ngx-translate/core";

export class MyMatPaginatorIntl extends MatPaginatorIntl {
  private nonEmptyRangeLabelIntl!: string;
  private emptyRangeLabelIntl!: string;

  constructor(
    private translateService: TranslateService,
    private translateParser: TranslateParser,
  ) {
    super();
    this.getTranslations();
    this.translateService.onLangChange.subscribe(() => this.getTranslations());
  }

  private getTranslations(): void {
    const trans = this.translateService.instant([
      "shared.matPaginator.ItemsPerPage",
      "shared.matPaginator.NextPage",
      "shared.matPaginator.PreviousPage",
      "shared.matPaginator.NonEmptyRange",
      "shared.matPaginator.EmptyRange",
    ]);

    this.itemsPerPageLabel = trans["shared.matPaginator.ItemsPerPage"];
    this.nextPageLabel = trans["shared.matPaginator.NextPage"];
    this.previousPageLabel = trans["shared.matPaginator.PreviousPage"];
    this.nonEmptyRangeLabelIntl = trans["shared.matPaginator.NonEmptyRange"];
    this.emptyRangeLabelIntl = trans["shared.matPaginator.EmptyRange"];

    this.getRangeLabel = this.getRangeLabel.bind(this);
    this.changes.next();
  }

  getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return (
        this.translateParser.interpolate(this.emptyRangeLabelIntl, {
          length: length,
        }) ?? ""
      );
    }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;

    return (
      this.translateParser.interpolate(this.nonEmptyRangeLabelIntl, {
        startIndex: startIndex + 1,
        endIndex: endIndex,
        length: length,
      }) ?? ""
    );
  };
}
