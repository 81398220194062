import { StorageKeys } from "../services/local-storage.service";
import { MySAPCookieAuthResponse } from "../services/responses/my-sap-cookie-auth-response";

export enum BrandWhiteLabelConfigKeys {
  WhiteLabelGradientColorHigh = "--gradient-color-high",
  WhiteLabelGradientColorLow = "--gradient-color-low",
  WhiteLabelThemeColorPrimary = "--theme-color-primary",
  WhiteLabelFooterColorBackground = "--footer-background-color",
  WhiteLabelFooterPadding = "--footer-padding",
}

export interface WhiteLabelKeys {
  styleVar: BrandWhiteLabelConfigKeys;
  storageKey: StorageKeys;
  cookieParam: keyof MySAPCookieAuthResponse;
}

export const WhiteLabelKeysList: WhiteLabelKeys[] = [
  {
    styleVar: BrandWhiteLabelConfigKeys.WhiteLabelThemeColorPrimary,
    storageKey: StorageKeys.WhiteLabelColorPrimary,
    cookieParam: "primaryColor",
  },
  {
    styleVar: BrandWhiteLabelConfigKeys.WhiteLabelFooterColorBackground,
    storageKey: StorageKeys.WhiteLabelGradientColorHigh,
    cookieParam: "gradientColorHigh",
  },
  {
    styleVar: BrandWhiteLabelConfigKeys.WhiteLabelGradientColorHigh,
    storageKey: StorageKeys.WhiteLabelGradientColorHigh,
    cookieParam: "gradientColorHigh",
  },
  {
    styleVar: BrandWhiteLabelConfigKeys.WhiteLabelGradientColorLow,
    storageKey: StorageKeys.WhiteLabelGradientColorLow,
    cookieParam: "gradientColorLow",
  },
];
