import { HttpErrorResponse } from "@angular/common/http";
import { Component, EventEmitter, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Meta } from "@angular/platform-browser";
import { lastValueFrom } from "rxjs";
import { CampaignService } from "../../../shared/services/api/campaign.service";
import { PartnerCampaignService } from "../../../shared/services/api/partner-campaign.service";
import { NotificationService } from "../../../shared/services/notification.service";
import { PartnerDeclineCampaignDialogComponent } from "../../../partner/partner-decline-campaign-dialog/partner-decline-campaign-dialog.component";
import { assert } from "../../../shared/utils/assert";

@Component({
  selector: "app-partner-accept-campaign",
  templateUrl: "./partner-accept-campaign.component.html",
  styleUrls: ["./partner-accept-campaign.component.scss"],
})
export class PartnerAcceptCampaignComponent {
  public submitting = false;
  public submitted = false;
  @Output() public partnerAnsweredInvite = new EventEmitter<boolean>();

  constructor(
    private readonly campaignService: CampaignService,
    private readonly partnerCampaignService: PartnerCampaignService,
    private readonly notificationService: NotificationService,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly meta: Meta,
  ) {
    this.addMetaTag();
  }

  addMetaTag(): void {
    this.meta.updateTag({
      name: "viewport",
      content: "width=device-width, initial-scale=1.0",
    });
  }

  public acceptCampaign(): void {
    this.submitting = true;
    this.partnerAnsweredInvite.emit(true);
    this.partnerCampaignService
      .partnerAcceptCampaignFromToken(this.getPartnerInviteToken())
      .subscribe({
        next: () => {
          this.submitting = false;
          this.submitted = true;
          setTimeout(() => {
            this.router.navigate(["welcome"]);
          }, 2000);
        },
        error: (error) => {
          this.showError(error);
        },
      });
  }

  public async declineCampaignConfirm(): Promise<void> {
    const declineReason: string | undefined = await lastValueFrom(
      this.dialog
        .open(PartnerDeclineCampaignDialogComponent, {
          data: {
            message: "partner.campaignList.declineConfirmTitle",
          },
        })
        .afterClosed(),
    );

    if (declineReason) {
      this.declineCampaign(declineReason);
    }
  }

  public declineCampaign(declineReason: string): void {
    this.submitting = true;
    this.partnerAnsweredInvite.emit(false);
    this.partnerCampaignService
      .partnerDeclineCampaignFromToken(
        this.getPartnerInviteToken(),
        declineReason,
      )
      .subscribe({
        next: () => {
          this.submitting = false;
          this.submitted = true;
          setTimeout(() => {
            this.router.navigate(["welcome"]);
          }, 2000);
        },
        error: (error) => {
          this.showError(error);
        },
      });
  }

  private getPartnerInviteToken(): string {
    assert(
      this.campaignService.partnerInviteToken,
      "Partner invite token not defined",
    );
    return this.campaignService.partnerInviteToken;
  }

  private showError(err: HttpErrorResponse): void {
    this.submitting = false;
    const error = err.error?.error;

    let message = "partner.acceptCampaign.errorJoiningCampaign";

    if (error?.key === "ALREADY_ACCEPTED") {
      message = "partner.acceptCampaign.alreadyAccepted";
    } else if (error?.key === "CAMPAIGN_ALREADY_FINISHED") {
      message = "partner.acceptCampaign.campaignIsAlreadyOver";
    } else if (error.details?.length) {
      message = err.error.error.details[0];
    }

    this.notificationService.error(message);
  }
}
