import { Component } from "@angular/core";
import { Meta } from "@angular/platform-browser";

@Component({
  templateUrl: "./account-verified.component.html",
  styleUrls: ["./account-verified.component.scss"],
})
export class AccountVerifiedComponent {
  constructor(meta: Meta) {
    meta.updateTag({
      name: "viewport",
      content: "width=device-width, initial-scale=1",
    });
  }

  continue(): void {
    window.location.href = "/";
  }
}
