<div class="content">
  <div>
    <img class="logo" src="/assets/images/as_socialpals_logo_orange.svg" />
    <h1>
      <p>{{ "static.accountVerified.title" | translate }}</p>
      <p>{{ "static.accountVerified.subtitle" | translate }}</p>
    </h1>
    <button (click)="continue()" class="button button-orange continue-button">
      {{ "static.accountVerified.button" | translate }}
    </button>
  </div>
</div>
