<div class="maintenance-body" *ngIf="isUnderMaintenance">
  <div class="maintenance-body-text">
    {{ "shared.appUnderMaintenance" | translate }}
  </div>
  <div class="maintenance-body-sub-text">
    {{ "shared.appUnderMaintenanceDescription" | translate }}
  </div>
</div>
<form
  *ngIf="!isUnderMaintenance"
  name="login-form"
  (ngSubmit)="login(f)"
  #f="ngForm"
  novalidate
>
  <h1>{{ "login.loginTitle" | translate }}</h1>
  <div class="form-body">
    <mat-form-field class="full-width">
      <mat-label>{{ "login.emailAddressPlaceholder" | translate }}</mat-label>
      <input
        matInput
        #username="ngModel"
        [(ngModel)]="model.username"
        id="username"
        name="username"
        type="email"
        required
        email
      />
      <mat-error
        class="email-error"
        *ngIf="username.errors && username.errors.required"
      >
        {{ "login.emailAddressRequired" | translate }}
      </mat-error>
      <mat-error
        class="email-error"
        *ngIf="
          username.errors && username.errors.email && !username.errors.required
        "
      >
        {{ "login.emailAddressInvalid" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field
      class="full-width password-field"
      [ngClass]="{ hidden: !showPasswordField }"
    >
      <mat-label>{{ "login.passwordPlaceholder" | translate }}</mat-label>
      <input
        matInput
        #password
        [(ngModel)]="model.password"
        id="password"
        name="password"
        type="password"
        required
      />
      <mat-error class="password-error">
        {{ "login.passwordRequired" | translate }}
      </mat-error>
    </mat-form-field>
    <a
      class="anchor-recover"
      [ngClass]="{ hidden: !showPasswordField }"
      [routerLink]="['/recover-password']"
      id="recover-password"
    >
      {{ "login.forgotPassword" | translate }}
    </a>
    <button class="button-orange" [disabled]="loading" id="login">
      {{ "login.loginButton" | translate }}
    </button>
    <a class="button-grey" [routerLink]="['/invite']" id="create-an-account">
      {{ "login.createAccount" | translate }}
    </a>
  </div>
</form>
