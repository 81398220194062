import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

import { UnsupportedBrowserComponent } from "./unsupported-browser.component";

export const routes: Routes = [
  { path: "", component: UnsupportedBrowserComponent },
];

@NgModule({
  declarations: [UnsupportedBrowserComponent],
  imports: [RouterModule.forChild(routes), TranslateModule],
  exports: [UnsupportedBrowserComponent],
})
export class UnsupportedBrowserModule {}
