<div class="maintenance-body" *ngIf="isUnderMaintenance">
  <div class="maintenance-body-text">
    {{ "shared.appUnderMaintenance" | translate }}
  </div>
  <div class="maintenance-body-sub-text">
    {{ "shared.appUnderMaintenanceDescription" | translate }}
  </div>
</div>
<form
  *ngIf="!isUnderMaintenance"
  name="form"
  (ngSubmit)="f.form.valid && isTermsAccepted && signup()"
  #f="ngForm"
  novalidate
>
  <!-- Invite Form -->
  <div *ngIf="!submitted">
    <h1>{{ "invite.signUp" | translate }}</h1>
    <div class="form-body">
      <mat-form-field class="full-width">
        <mat-label>{{ "invite.emailPlaceholder" | translate }}</mat-label>
        <input
          matInput
          #username="ngModel"
          [disabled]="isBrandInvitedUser"
          [(ngModel)]="user.email"
          id="email"
          name="email"
          type="email"
          required
          email
        />
        <mat-error *ngIf="username.errors && username.errors.required">
          {{ "invite.emailRequired" | translate }}
        </mat-error>
        <mat-error
          *ngIf="
            username.errors &&
            username.errors.email &&
            !username.errors.required
          "
        >
          {{ "invite.enterValidEmail" | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>{{ "invite.passwordPlaceholder" | translate }}</mat-label>
        <input
          matInput
          #password="ngModel"
          [(ngModel)]="user.password"
          id="password"
          name="password"
          [type]="showPassword ? 'text' : 'password'"
          pattern="{{ PASSWORD_PATTERN }}"
          required
        />
        <button
          mat-icon-button
          matSuffix
          (click)="showPassword = !showPassword"
          [attr.aria-label]="
            (showPassword ? 'invite.hidePassword' : 'invite.showPassword')
              | translate
          "
          [attr.aria-pressed]="!showPassword"
        >
          <mat-icon>{{
            showPassword ? "visibility" : "visibility_off"
          }}</mat-icon>
        </button>
        <mat-error *ngIf="password.errors && password.errors.required">
          {{ "invite.passwordRequired" | translate }}
        </mat-error>
        <mat-error *ngIf="password.errors && password.errors.pattern">
          {{ "invite.passwordPattern" | translate }}
        </mat-error>
      </mat-form-field>
      <div class="panel-terms">
        <div class="panel-terms-input-container">
          <mat-slide-toggle
            #terms="ngModel"
            [(ngModel)]="isTermsAccepted"
            name="terms"
            ariaLabelledby="terms-label"
            required
          >
          </mat-slide-toggle>
          <div
            (click)="checkTermsLink($event)"
            class="panel-terms-text"
            id="terms-label"
            [innerHTML]="'invite.terms' | translate"
          ></div>
        </div>
        <div class="terms-error" *ngIf="f.submitted && !isTermsAccepted">
          {{ "invite.acceptTerms" | translate }}
        </div>
      </div>
      <button class="button-orange" [disabled]="submitting" id="create-account">
        {{ "invite.createAccount" | translate }}
      </button>
      <a class="back-to-login" [routerLink]="['/login']" id="back-to-login">
        {{ "invite.alreadyHaveAccount" | translate }}
      </a>
    </div>
  </div>
  <!-- Sign Up Success message -->
  <div *ngIf="submitted">
    <h1>{{ "invite.signUpSuccess" | translate }}</h1>
    <div class="form-body-success">
      <h2>{{ "invite.thankYou" | translate }}</h2>
      <img
        class="img-screen"
        src="../../../assets/images/as_computer_email.svg"
      />
      <p>{{ "invite.instructions" | translate }}</p>
      <p>{{ "invite.instructionsTwo" | translate }}</p>
      <button class="button-orange" [routerLink]="['/login']">
        {{ "invite.login" | translate }}
      </button>
    </div>
  </div>
</form>
